<template>
  <el-dialog
    v-fullscreen
    :visible.sync="dialogVisible"
    width="40%"
    top="10vh"
    custom-class="contract-type"
    :close-on-click-modal="false"
    v-on="$listeners">
    <span slot="title" class="base-title-font">
      <em
        :style="{color:$store.state.currentTheme.primaryColor}"
        class="iconfont icon-yuandianzhong">
      </em>
      Add Terminal or Berth
    </span>
    <div class="body">
      <el-form
        ref="ruleForm"
        :model="locationFormData"
        v-loading="loading"
        >
          <el-form-item
            label="Terminal"
            prop="parentId"
            :rules="[{ required: true, message: 'This field is required', trigger: ['blur', 'change'] }]">
            <el-select
              filterable
              style="width: 100%"
              v-model="locationFormData.parentId"
              value-key="id"
            >
              <el-option
                v-for="terminal in locationParentOptionList"
                :key="terminal.id"
                :label="terminal.name"
                :value="terminal.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Name"
            prop="name"
            :rules="[{ required: true, message: 'This field is required', trigger: ['blur', 'change'] }]">
            <el-input
              v-model="locationFormData.name">
            </el-input>
          </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <el-button
        size="meduim"
        round
        @click="handleClose">
        {{ $t('cancel') }}
      </el-button>
      <el-button
        size="meduim"
        round
        type="primary"
        :loading="loading"
        @click="submit">
        {{ $t('submit') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
/*
  只允许Admin操作，其他角色不可以提交数据.
  Only Admin can post new location
*/
export default {
  name: 'AddTerminalAndBerth',
  props: {
    visible: Boolean
  },
  data () {
    const initFormData = {
      parentId: null,
      name: null
    }
    return {
      locationFormData: this.$_.cloneDeep(initFormData),
      dialogVisible: false,
      loading: false,
      selectNone: { id: 'undefind', name: '----- Select None -----', parentId: null },
      locationParentOptionList: this.$_.cloneDeep(this.$store.state.locationList)
    }
  },
  created () {
    this.dialogVisible = true
    this.locationParentOptionList.unshift(this.selectNone)
  },
  methods: {
    handleClose () {
      this.$emit('closed')
      this.dialogVisible = false
    },
    submit () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.locationFormData.parentId === 'undefind') {
            this.locationFormData.parentId = null
          }
          this.postLocationOrBerth([this.locationFormData])
        }
      })
    },
    postLocationOrBerth (payload) {
      return this.$request.post({
        url: this.$apis.location,
        data: payload
      }).then(res => {
        if (res?.code === 1000) {
          this.$store.dispatch('getLocationList')
          this.handleClose()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.contract-type {
  .type-container {
    @include flex-center-column;

    > span {
      font-size: 20px;
    }

    .btn {
      display: flex;
      flex-wrap: wrap;
      padding: 20px;
    }
  }

}
</style>
